import PropTypes from 'prop-types';

function Link({ href, className, target, rel, children }) {
  return (
    <a href={href} className={className} target={target} rel={rel}>
      {children}
    </a>
  );
}

Link.propTypes = {
  href: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  target: PropTypes.string,
  rel: PropTypes.string,
  children: PropTypes.node,
};

Link.defaultProps = {
  href: '',
  className: '',
  target: '',
  rel: '',
  children: null,
};

export default Link;
