import React from 'react';
import PropTypes from 'prop-types';

function Text({ variant = 'p', className, children }) {
  const Component = variant;

  return <Component className={className}>{children}</Component>;
}

Text.propTypes = {
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.node,
};

Text.defaultProps = {
  variant: ['p', 'h1'],
  className: '',
  children: null,
};

export default Text;
