import PropTypes from 'prop-types';

function Logo({ width = 160, height = 46, className }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 160 46"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="trailinq"
      className={className}
    >
      <title>trailinq logo</title>
      <g clipPath="url(#clip0_2_694)">
        <path d="M0 12.3954C0 10.9576 1.14038 9.86676 2.52866 9.86676H4.3136V6.14813C4.3136 4.6111 5.50356 3.42114 7.04059 3.42114C8.57763 3.42114 9.76759 4.6111 9.76759 6.14813V9.86676H15.5686C16.9569 9.86676 18.0973 10.9576 18.0973 12.3954C18.0973 13.8333 16.9569 14.9241 15.5686 14.9241H9.76759V23.8984C9.76759 28.2616 12.1971 30.4927 15.5686 30.4927C16.9569 30.4927 18.0973 31.6331 18.0973 33.0214C18.0973 34.4097 16.9569 35.5996 15.5686 35.5996C9.17261 35.5996 4.3136 31.0877 4.3136 23.8984V14.9241H2.52866C1.14038 14.9241 0 13.7837 0 12.3954Z" />
        <path d="M22.6092 20.9731C22.6092 13.9325 27.3195 9.37097 33.7651 9.37097C35.1534 9.37097 36.2938 10.4618 36.2938 11.8996C36.2938 13.3375 35.1534 14.4283 33.7651 14.4283C30.4927 14.4283 28.0632 16.6099 28.0632 20.9731V32.8231C28.0632 34.3601 26.8733 35.5997 25.3362 35.5997C23.7992 35.5997 22.6092 34.3601 22.6092 32.8231V20.9731Z" />
        <path d="M59.0022 32.5752C56.8206 34.5089 53.9448 35.6493 50.7716 35.6493C43.6318 35.6493 38.2274 29.8482 38.2274 22.5101C38.2274 15.172 43.6318 9.37097 50.7716 9.37097C53.9448 9.37097 56.8206 10.5113 59.0022 12.4946V12.1475C59.0022 10.6105 60.1921 9.42055 61.7292 9.42055C63.2662 9.42055 64.4561 10.6105 64.4561 12.1475V32.8231C64.4561 34.3601 63.2662 35.5997 61.7292 35.5997C60.1921 35.5997 59.0022 34.3601 59.0022 32.8231V32.5752ZM59.0022 22.5101V22.163C58.8038 17.7503 55.5314 14.4283 51.3666 14.4283C47.2017 14.4283 43.731 17.899 43.731 22.5101C43.731 27.1212 47.1026 30.5919 51.3666 30.5919C55.6306 30.5919 59.0022 27.1212 59.0022 22.5101Z" />
        <path d="M70.3068 3.32193C70.3068 1.53699 71.7447 0.0991211 73.5296 0.0991211C75.3145 0.0991211 76.7524 1.53699 76.7524 3.32193C76.7524 5.10687 75.3145 6.54474 73.5296 6.54474C71.7447 6.54474 70.3068 5.05729 70.3068 3.32193ZM70.8026 12.1475C70.8026 10.6104 71.9926 9.42047 73.5296 9.42047C75.0666 9.42047 76.2566 10.6104 76.2566 12.1475V32.823C76.2566 34.36 75.0666 35.5996 73.5296 35.5996C71.9926 35.5996 70.8026 34.36 70.8026 32.823V12.1475Z" />
        <path d="M82.603 2.72699C82.603 1.18996 83.793 0 85.33 0C86.8671 0 88.057 1.18996 88.057 2.72699V32.8231C88.057 34.3601 86.8671 35.5996 85.33 35.5996C83.793 35.5996 82.603 34.3601 82.603 32.8231V2.72699Z" />
        <path d="M93.9077 3.32193C93.9077 1.53699 95.3455 0.0991211 97.1305 0.0991211C98.9154 0.0991211 100.353 1.53699 100.353 3.32193C100.353 5.10687 98.9154 6.54474 97.1305 6.54474C95.3455 6.54474 93.9077 5.05729 93.9077 3.32193ZM94.4035 12.1475C94.4035 10.6104 95.5934 9.42047 97.1305 9.42047C98.6675 9.42047 99.8574 10.6104 99.8574 12.1475V32.823C99.8574 34.36 98.6675 35.5996 97.1305 35.5996C95.5934 35.5996 94.4035 34.36 94.4035 32.823V12.1475Z" />
        <path d="M106.204 20.9235C106.204 13.8333 111.112 9.37097 117.36 9.37097C123.607 9.37097 128.516 13.8333 128.516 20.9235V32.8231C128.516 34.3601 127.276 35.5997 125.789 35.5997C124.301 35.5997 123.062 34.3601 123.062 32.8231V20.7252C123.062 16.6099 120.533 14.4283 117.36 14.4283C114.187 14.4283 111.658 16.6099 111.658 20.7252V32.8231C111.658 34.3601 110.468 35.5997 108.931 35.5997C107.394 35.5997 106.204 34.3601 106.204 32.8231V20.9235Z" />
        <path d="M154.546 32.5752C152.364 34.5089 149.489 35.6492 146.315 35.6492C139.176 35.6492 133.771 29.8482 133.771 22.5101C133.771 15.172 139.176 9.37097 146.315 9.37097C149.489 9.37097 152.364 10.5113 154.546 12.4946V12.1475C154.546 10.6105 155.736 9.42055 157.273 9.42055C158.81 9.42055 160 10.6105 160 12.1475V42.2932C160 43.8302 158.81 45.0202 157.273 45.0202C155.736 45.0202 154.546 43.8302 154.546 42.2932V32.5752ZM154.546 22.5101V22.163C154.348 17.7503 151.075 14.4283 146.91 14.4283C142.746 14.4283 139.275 17.899 139.275 22.5101C139.275 27.1212 142.646 30.5919 146.91 30.5919C151.174 30.5919 154.546 27.1212 154.546 22.5101Z" />
      </g>
      <defs>
        <clipPath id="clip0_2_694">
          <rect width="160" height="45.0201" />
        </clipPath>
      </defs>
    </svg>
  );
}

Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

Logo.defaultProps = {
  width: 160,
  height: 46,
  className: '',
};

export default Logo;
