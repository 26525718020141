import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

if (
  localStorage.theme === 'dark' ||
  (!('theme' in localStorage) &&
    window.matchMedia('(prefers-color-scheme: dark)').matches)
) {
  document.documentElement.classList.add('dark');
} else {
  document.documentElement.classList.remove('dark');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className="pointer-events-none absolute left-2 top-2 z-50">
      <a
        href="#main"
        className="
          focus-visible:outline-state-focus
          tracking-ff-tight text-body-sm
          text-shade-100
          hover:bg-shade-30
          active:bg-shade-50
          disabled:bg-shade-70
          disabled:text-shade-90
          inline-block
          max-w-full
          translate-y-[-200%]
          overflow-hidden
          rounded-[0.25rem]
          border-2
          border-solid
          border-[transparent]
          bg-textColor
          px-[1rem]
          py-[0.875rem]
          text-label-medium
          font-bold
          leading-none
          text-white 
          transition-transform
          duration-300
          focus:z-[100]
          focus:translate-y-0
          focus-visible:outline-none
          focus-visible:outline-offset-[-2px]
          focus-visible:outline-textColor
          motion-reduce:transition-none
          dark:bg-white
          dark:text-textColor
          dark:focus-visible:outline-white
        "
      >
        Skip to content
      </a>
    </div>
    <App />
  </React.StrictMode>,
);

// rounded-[0.25rem] border border-conversion bg-conversion px-[1rem] py-[0.875rem] text-label-medium font-bold
// transition-all hover:bg-on-conversion-hover

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
