import React, { useState } from 'react';

import Link from './Components/Link';
import Logo from './Components/Logo';
import Text from './Components/Text';

import {
  IconBrandTwitter,
  IconBrandFacebook,
  IconBrandInstagram,
  IconDeviceDesktop,
} from '@tabler/icons-react';

function App() {
  // region Newsletter
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email,
    );

  const handleSubscribe = async () => {
    if (!isValidEmail(email)) {
      setIsInvalidEmail(true);
      return;
    }
    setIsInvalidEmail(false);

    const url = new URL('https://api.sender.net/v2/subscribers');
    const headers = {
      Authorization:
        'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiODE2YTU2YTI2MzFhZTNhZWEyMWFiNGUyZjAzY2FhZWI3OGM0ZjFkMWYzOTllN2Q3Y2ViYTBlYTBiNDhlNDBlY2I3Mjg1NTk5ODI1YTA0YWQiLCJpYXQiOiIxNjkyNTU5MzY1LjM5NzE5MiIsIm5iZiI6IjE2OTI1NTkzNjUuMzk3MTk2IiwiZXhwIjoiNDg0NjE1OTM2NS4zOTQzNTIiLCJzdWIiOiI4MDg4MTgiLCJzY29wZXMiOltdfQ.TMhJ9FtcRSBQDzUwfktYf5yoxquRoCuRgKpWY0lmZlG9PJ1SSPTGavUcHHGbAdfZH_oJ0iE8qwMKgQqKfAB1wg',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    const data = {
      email: email,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      });

      const responseData = await response.json();

      // Check if the response contains an error or success message (you might need to adjust this depending on the actual response format)
      if (responseData.success) {
        setIsSubscribed(true);
      } else {
        console.error(responseData.error);
      }
    } catch (error) {
      console.error('Error subscribing the email:', error);
    }
  };
  // endregion Newsletter

  return (
    <div className={'selection:bg-conversion'}>
      {/* region Header*/}
      <header
        className={
          'border-b-on-white-border ' +
          'dark:border-b-on-navy-border ' +
          'w-full ' +
          'border-b'
        }
      >
        <div className="transition-colors duration-700">
          <div className="container mx-auto flex h-global-header items-center px-[16px]  md:px-[24px] xl:w-full xl:px-0">
            <Link
              href="#"
              className="focus-visible:outline-offset-4 focus-visible:outline-textColor dark:focus-visible:outline-white"
            >
              <Logo
                className="fill-on-white dark:fill-white"
                width={100}
                height={28}
              />
            </Link>
            <div className="ml-auto">
              <nav className="flex space-x-4">
                <Link
                  href="https://www.facebook.com/trailinq"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-on-white hover:text-conversion focus-visible:outline-offset-4 focus-visible:outline-textColor dark:text-white dark:hover:text-conversion dark:focus-visible:outline-white"
                >
                  <IconBrandFacebook />
                </Link>
                <Link
                  href="https://www.instagram.com/gotrailinq/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-on-white hover:text-conversion focus-visible:outline-offset-4 focus-visible:outline-textColor dark:text-white dark:hover:text-conversion dark:focus-visible:outline-white"
                >
                  <IconBrandInstagram />
                </Link>
                <Link
                  href="https://twitter.com/trailinq"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-on-white hover:text-conversion focus-visible:outline-offset-4 focus-visible:outline-textColor dark:text-white dark:hover:text-conversion dark:focus-visible:outline-white"
                >
                  <IconBrandTwitter />
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* endregion Header */}
      {/* region Main */}
      <main
        id="main"
        className={'mt-[3rem] items-center px-[16px] sm:mt-[5rem] md:px-[24px]'}
      >
        {/* region Heading */}
        <section className={'md:mx-auto lg:w-9/12 xl:w-6/12'}>
          {/* region Title */}
          <Text
            variant="h1"
            className={
              'text-h1 ' +
              'sm:text-sm-h1 ' +
              'text-center ' +
              'font-heading ' +
              'text-on-white ' +
              'dark:text-white'
            }
          >
            Discover the{' '}
            <span
              className={
                'text-conversion selection:bg-textColor dark:selection:bg-on-conversion'
              }
            >
              future
            </span>{' '}
            of event tracking
          </Text>
          {/* endregion Title */}
          {/* region Sub Title */}
          <Text
            variant="p"
            className={
              'text-p-large ' +
              'sm:text-sm-p-large ' +
              'mb-[3rem] ' +
              'mt-[1.5rem] ' +
              'text-center ' +
              'font-sans ' +
              'text-on-white ' +
              'dark:text-white ' +
              'md:mx-auto ' +
              'md:w-10/12 ' +
              'xl:w-8/12'
            }
          >
            Trail the digital journey. Seamless integration with a multitude of
            third-party platforms.
          </Text>
          {/* endregion Sub Title */}
        </section>
        {/* endregion Heading */}
        {/* region Newsletter */}
        <section className={'mx-auto'}>
          <div className={'sm:mx-auto sm:flex sm:w-8/12 lg:w-6/12 xl:w-4/12'}>
            <div className={'mb-[8px] sm:mb-0 sm:flex-grow'}>
              <input
                type={'email'}
                placeholder={
                  isSubscribed ? 'You have been subscribed' : 'Enter your email'
                }
                value={isSubscribed ? '' : email}
                onChange={(e) => setEmail(e.target.value)}
                className={
                  'w-full ' +
                  'rounded-[0.25rem] ' +
                  'border border-solid ' +
                  'border-gray-100 ' +
                  (isInvalidEmail ? 'border-red-400 ' : 'border-gray-100 ') +
                  'px-[1rem] ' +
                  'py-[0.75rem] ' +
                  'text-label-medium'
                }
                disabled={isSubscribed}
              />
            </div>
            <button
              className={
                'dark:border-b-on-navy-border ' +
                'w-full rounded-[0.25rem] ' +
                'border ' +
                (isSubscribed ? 'border-primary ' : 'border-conversion ') +
                (isSubscribed ? 'bg-primary ' : 'bg-conversion ') +
                'px-[1rem] ' +
                'py-[0.875rem] ' +
                'text-label-medium ' +
                'font-bold ' +
                'text-on-conversion ' +
                'transition-all ' +
                (isSubscribed ? '' : 'hover:bg-on-conversion-hover ') +
                'sm:ml-[8px] ' +
                'sm:w-auto'
              }
              onClick={handleSubscribe}
              disabled={isSubscribed}
            >
              {isSubscribed ? 'Subscribed' : 'Notify Me'}
            </button>
          </div>
          <div>
            {isInvalidEmail && (
              <Text
                variant={'p'}
                className={
                  'text-p ' +
                  'text-red-400 ' +
                  'text-center ' +
                  'font-sans ' +
                  'text-on-white ' +
                  'dark:text-red ' +
                  'md:mx-auto ' +
                  'md:w-10/12 ' +
                  'xl:w-8/12'
                }
              >
                Please enter a valid email address
              </Text>
            )}
          </div>
        </section>
        {/* endregion Newsletter */}
        <section className={'container mx-auto items-center sm:w-full sm:px-0'}>
          {/* region Hero Image */}
          <div
            className={
              'mx-auto my-[3rem] items-center selection:bg-conversion sm:my-[5rem]'
            }
          >
            <picture className={'items-center'}>
              <source
                media="(min-width: 650px)"
                srcSet={'/hero.svg'}
                className={'mx-auto selection:bg-conversion'}
              />
              <img
                src={'/hero-mobile.svg'}
                alt={'Data flow event tracking'}
                className={'mx-auto items-center selection:bg-conversion'}
              />
            </picture>
          </div>
          {/* endregion Hero Image */}
        </section>
        <section
          className={'container mx-auto mb-[32px] items-center md:mb-[80px] '}
        >
          <div
            className={
              'container mx-auto items-center lg:grid lg:grid-cols-3 lg:gap-[3rem] '
            }
          >
            {/* region Block 1 */}
            <div className={'mb-[3rem]'}>
              <IconDeviceDesktop
                size={32}
                className={'text-textColor dark:text-white'}
              />
              <Text
                variant={'h2'}
                className={
                  'text-h2 ' +
                  'sm:text-sm-h2 ' +
                  'mb-[8px] ' +
                  'mt-[16px] ' +
                  'font-heading ' +
                  'text-textColor ' +
                  'dark:text-white'
                }
              >
                Unified Source Integration
              </Text>
              <Text variant={'p'} className={'text-textColor dark:text-white'}>
                Integrate effortlessly across platforms — iOS, Android, Web,
                Helpdesk, or Marketing. Streamline your data from all
                touchpoints into one central hub.
              </Text>
            </div>
            {/* endregion Block 1*/}
            {/* region Block 2 */}
            <div className={'mb-[3rem]'}>
              <IconDeviceDesktop
                size={32}
                className={'text-textColor dark:text-white'}
              />
              <Text
                variant={'h2'}
                className={
                  'text-h2 ' +
                  'sm:text-sm-h2 ' +
                  'mb-[8px] ' +
                  'mt-[16px] ' +
                  'font-heading ' +
                  'text-textColor ' +
                  'dark:text-white'
                }
              >
                Real-Time Data Processing
              </Text>
              <Text variant={'p'} className={'text-textColor dark:text-white'}>
                Experience the power of real-time data routing. As events
                unfold, <span className={'text-conversion'}>trailinq</span> is
                right there, ensuring instantaneous data movement and accuracy.
              </Text>
            </div>
            {/* endregion Block 2 */}
            {/* region Block 3 */}
            <div className={'sm:w-12/12 mb-[3rem]'}>
              <IconDeviceDesktop
                size={32}
                className={'text-textColor dark:text-white'}
              />
              <Text
                variant={'h2'}
                className={
                  'text-h2 ' +
                  'sm:text-sm-h2 ' +
                  'mb-[8px] ' +
                  'mt-[16px] ' +
                  'font-heading ' +
                  'text-textColor ' +
                  'dark:text-white'
                }
              >
                <span className={'hidden xl:inline-block'}>Broad </span>{' '}
                Third-Party Connectivity
              </Text>
              <Text variant={'p'} className={'text-textColor dark:text-white'}>
                Extend your reach with our vast array of third-party
                integrations. We've got you linked, whether it's data analytics,
                storage, or Customer Relation Management.
              </Text>
            </div>
            {/* endregion Block 3 */}
          </div>
        </section>
      </main>
      {/* endregion Main */}
    </div>
  );
}

export default App;
